import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

interface PaginationProps {
  page: number;
  totalPage: number;
  next: () => void;
  prev: () => void;
}
export const Pagination = ({
  page,
  totalPage,
  next,
  prev,
}: PaginationProps) => {
  return (
    <div className="flex items-center gap-4">
      <button onClick={prev} disabled={page === 1}>
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
      </button>
      <p>
        {" "}
        Page <strong className="text-gray-900">{page}</strong> of{" "}
        <strong className="text-gray-900">{totalPage}</strong>
      </p>
      <button onClick={next} disabled={page === totalPage}>
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </button>
    </div>
  );
};
