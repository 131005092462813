import axios from "axios";

// const BACKEND_REST_API_URL = "http://43.218.87.74:3000/api/v1";
const BACKEND_REST_API_URL = "https://api.withmudita.com/api/v1";

const axiosInstance = axios.create({
  baseURL: BACKEND_REST_API_URL,
  headers: {
    Authorization: ``,
    "Access-Control-Allow-Origin": "*",
  },
});

export { axiosInstance };
