import {Us} from "./components/us";
import {Founder} from "./components/founder";
import {ContactUs} from "../home_page/components/contact_us";
import {Template} from "../../components/template";
import {useEffect} from "react";

export const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <Template>
      <Us/>
      <Founder/>
      <ContactUs/>
    </Template>
  );
};
