import Icon from "../../../../../../assets/svg/langkah_konseling_icon.svg";

export const LangkahKonseling = () => {
  return (
    <div className="h-full w-full flex items-center justify-center bg-ceritakan-masalahmu bg-no-repeat bg-cover rounded-[12px] relative">
      <div className="flex items-center justify-between px-4 md:px-16 h-full w-[95%]">
        <div className="absolute bottom-[40px] sm:bottom-[80px] md:bottom-28">
          <h1 className="font-bold text-cream text-[16px] sm:text-[24px] sm:text-[32px] md:text-[40px] xl:text-[56px] ">
            Langkah
          </h1>
          <h1 className="font-bold text-white text-[16px] sm:text-[24px] sm:text-[32px] md:text-[40px] xl:text-[56px] ">
            Konseling
          </h1>
        </div>
        <img src={Icon} alt="icon" className="w-fit absolute top-0 right-0" />
      </div>
    </div>
  );
};
