interface Props {
  items: string[];
}

const OverflowChip = ({ items }: Props) => {
  const maxWidth = 342;

  const renderChipContent = () => {
    let totalWidth = 0;
    let maxVisibleItems = 0;

    for (let i = 0; i < items.length; i++) {
      // assume each character takes 10px,
      // padding takes 24px
      // gap between chips 8px
      const itemWidth = items[i].length * 10 + 24 + 8;

      if (totalWidth + itemWidth <= maxWidth) {
        totalWidth += itemWidth;
        maxVisibleItems++;
      } else {
        break;
      }
    }

    const visibleItems = items.slice(0, maxVisibleItems);
    const overflowCount = items.length - maxVisibleItems;

    return (
      <>
        {visibleItems.map((item, index) => (
          <span
            key={index}
            className="bg-gray rounded-[16px] text-[13px] px-[12px] py-[6px]"
          >
            {item}
          </span>
        ))}
        {overflowCount > 0 && (
          <span className="bg-white border-[1px] border-gray rounded-[16px] text-[13px] px-[12px] py-[6px]">{`${overflowCount}+`}</span>
        )}
      </>
    );
  };

  return <div className="flex gap-[8px]">{renderChipContent()}</div>;
};

export default OverflowChip;
