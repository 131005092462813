import './style.css';

export const Seperator = () => {
    return (
        <div className='seperator'>
            <div className='overlap-group'>
                <div className='rectangle' />
                <div className='div' />
            </div>
        </div>
    );
};
