import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Router} from "./router/router";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {Router.map((route) => {
          const Component = route.component;
          return (
            <Route key={route.path} path={route.path} element={<Component/>}/>
          );
        })}
      </Routes>
    </BrowserRouter>
  )
}

export default App;
