export const Icons = {
  broken_link: require("./broken_link.png"),
  call: require("./call.png"),
  copy: require("./copy.png"),
  graph: require("./graph.png"),
  heart_pulse: require("./heart_pulse.png"),
  logout: require("./logout.png"),
  storm: require("./storm.png"),
  arrow: require("./arrow.png"),
  phone: require("./phone.png"),
  map: require("./map.png"),
  message: require("./message.png"),

  copy_only: require("./copy_only.png"),
  dialog_only: require("./dialog_only.png"),
  lightbulb_only: require("./lightbulb_only.png"),
  microphone_only: require("./microphone_only.png"),
  users_only: require("./users_only.png"),
  rectangle: require("./rectangle.png"),
};
