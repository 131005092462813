import {axiosInstance} from "../axios";
import useSWR from "swr";
import {Topic} from "../../pages/counseling/components/practitioner_list";

export default function usePractitionerTopicList() {
  return useSWR<Topic[]>('/topic', async () => {
    const response = await axiosInstance.get<Topic[]>("/topic");
    return response.data
  });
}
