import PengalamanIcon from "../../assets/svg/pengalaman_icon.svg";
import OverflowChip from "../overflow_chip";
import {Practitioner} from "../../pages/counseling/components/practitioner_list";

interface Props {
  data: Practitioner;
}

export const PractitionerCard = ({data}: Props) => {
  const topicList = data.topicId.map((topic) => topic.name);

  return (
    <div className="flex flex-col w-[300px] sm:min-w-[380px] h-[220px] rounded-[12px] bg-white p-[24px] gap-[8px]">
      <div className="flex gap-[15px] max-w-[332px]">
        <img
          src={data.imageUrl}
          alt="practitioner"
          className="w-[80px] h-[80px] rounded-full object-cover object-top aspect-square"
        />
        <div>
          <h1 className="text-[15px] w-[160px] sm:max-w-[220px] whitespace-nowrap text-ellipsis overflow-x-hidden inline-block">
            {data.fullName + ", " + data.title}
          </h1>
          <p className="text-[13px] text-orange">{data.specialityId.name}</p>
          {data.specialityId.name !== "Counselor" && (
            <div className="flex gap-[8px] mt-[8px]">
              <img src={PengalamanIcon} alt="pengalaman" />
              <p className="text-[13px]">{data.experience} tahun pengalaman</p>
            </div>
          )}
          {/* <div className="flex gap-[8px] ">
            <img src={SesiIcon} alt="sesi" />
            <p className="text-[13px]">{data.sessionConducted} sesi</p>
          </div> */}
        </div>
      </div>
      <div className="w-full">
        <OverflowChip items={topicList} />
      </div>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-[13px]">Mulai</p>
          <p className="text-[15px]">
            Rp{parseInt(data.specialityId.price).toLocaleString("id-ID")}
          </p>
        </div>
        <p className="text-primary font-bold text-[13px]">
          Jadwalkan Sesi
        </p>
      </div>
    </div>
  );
};
