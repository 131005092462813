import { useEffect } from "react";
import "./style.css";
import { OurClient } from "./components/our_client";
import { Intro } from "./components/intro";
import { CompanyProblem } from "./components/company_problem";
import { CompanyResource } from "./components/company_resources";
import { Reviews } from "./components/reviews";
import { CompanySolution } from "./components/company_solution";
import { OurServices } from "./components/our_services";
import { ContactUsCard } from "./components/contact_us_card";
import { ContactUs } from "./components/contact_us";
import { Template } from "../../components/template";

export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <Template>
      <div className="grid gap-16">
        <Intro />
        <OurClient />
        <CompanyProblem />
        <CompanyResource />
        <CompanySolution />
        <OurServices />
        <Reviews />
        <ContactUsCard />
        <ContactUs />
      </div>
    </Template>
  );
};
