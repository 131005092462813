import PengalamanIcon from "../../assets/svg/pengalaman_icon.svg";
import {Practitioner} from "../../pages/counseling/components/practitioner_list";
import {IoChevronBackOutline} from "react-icons/io5";

interface Props {
  activePerson: Practitioner;
  isOpen: boolean;
  onClose: () => void;
}

export const PractitionerModal = ({activePerson, isOpen, onClose}: Props) => {
  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 max-w-screen-md w-full max-h-screen overflow-y-scroll">
        <div className="flex justify-end">
          {/* <button
            className="text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            Close
          </button> */}
        </div>
        <div className="mt-4">
          <div className="p-[20px] h-full">
            <h1 className="flex items-center gap-[10px] font-bold text-[20px] mb-6">
              <button onClick={onClose}>
                <IoChevronBackOutline/>{" "}
              </button>
              <span className="text-orange">Profil </span>Praktisi
            </h1>
            <div className="flex flex-col gap-[20px]">
              <div className="flex flex-col lg:flex-row gap-[40px]">
                <div
                  className="flex flex-col w-full lg:w-[50%] h-fit bg-white rounded-[10px] p-[20px] items-center text-center">
                  <div className="flex flex-col mb-2">
                    <img
                      src={activePerson?.imageUrl}
                      alt="practitioner"
                      className="w-[100px] h-[100px] rounded-full object-cover object-top aspect-square mx-auto mb-[10px]"
                    />
                    <p>
                      {activePerson?.fullName} {activePerson?.title}
                    </p>
                    <p className="text-[13px] text-orange">
                      {activePerson?.specialityId.name}
                    </p>
                  </div>
                  <div className="flex justify-evenly w-full border-t-[1px] pt-2 border-gray">
                    {activePerson?.specialityId.name !== "Counselor" &&
                        <div className="flex flex-col">
                            <div className="flex gap-[4px]">
                                <img src={PengalamanIcon} alt="pengalaman"/>
                                <p className="text-[12px]">pengalaman</p>
                            </div>
                            <p>{activePerson?.experience}</p>
                        </div>
                    }
                    {/* <div className="flex flex-col"> */}
                    {/* <div className="flex gap-[4px]">
                        <img src={SesiIcon} alt="sesi" />
                        <p className="text-[12px]">sesi terlaksana</p>
                      </div> */}
                    {/* <p>{activePerson?.sessionConducted}</p> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="flex flex-col  w-full lg:w-[50%] gap-[10px]">
                  <div>
                    <p className="font-bold mb-2">Kata Kunci</p>
                    <div>
                      {activePerson?.topicId.map((topic) => (
                        <button
                          className="border border-gray px-[9px] py-[4px] text-[13px] rounded-[20px] w-fit whitespace-nowrap m-[2px] bg-gray"
                          key={topic.name}
                        >
                          {topic.name}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className="font-bold mb-1">Deskripsi</p>
                    <p className="text-[13px]"> {activePerson?.description}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse">
                <div className="md:w-[50%]">
                  <button
                    className="bg-primary from-primary-gradient-end to-primary px-[32px] py-[10px] rounded-[26px] w-full"
                    onClick={() => window.open(activePerson?.url)}
                  >
                    <div className="text-white text-caption">
                      Jadwalkan Sesi
                    </div>
                  </button>
                </div>
                <div className="md:w-[50%] mb-4 md:text-center">
                  <p className="font-bold mb-1">Surat Izin Praktik Psikologi</p>
                  <p className="text-[14px]">{activePerson?.sippCode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
