import { Images } from "../../../../assets/png";
import { Seperator } from "../../../../components/seperator";

export const Intro = () => {
  return (
    <div className="flex flex-col md:flex-row container md:mb-24">
      <div className="sm:w-[80%] md:w-[50%] xl:w-[50%]">
        <img className="" src={Images.intro} alt="intro" />
      </div>
      <div className="w-[85%] m-auto md:m-0 md:w-[50%] xl:w-[50%]">
        <p className="mb-8 mt-4 md:mt-0">
          <span className="text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap font-bold">
            <span className=" text-orange">Psychological Approach </span>
            <span className="hidden md:inline">{"\n"}</span>for Your
            Institution's
            <span className="hidden md:inline">{"\n"}</span>
            <span className="md:hidden"> </span>
            Success
          </span>
        </p>
        <Seperator />
        <p className="mt-8 md:max-w-[70%] 2xl:max-w-[90%] text-[14px] sm:text-base">
          <span className="">
            Kami menyediakan solusi
            <span className="font-bold"> kredibel </span>
            dan
            <span className="font-bold"> terpersonalisasi </span>
          </span>
          untuk masalah personal dan interpersonal di institusi Anda. Bersama
          kami, temukan
          <span className="font-bold">
            {" "}
            solusi produktivitas dan kesehatan mental
          </span>{" "}
          untuk suksesnya institusi Anda!
        </p>
        <div className="pt-8">
          <button
            className="bg-gradient-custom from-primary-gradient-end to-primary px-[32px] py-[14px] rounded-[26px]"
            onClick={() => window.open("https://wa.me/6282211624891")}
          >
            <div className="text-white text-caption">Hubungi Kami</div>
          </button>
          <button className="sec-button">
            <div className="text-caption">Unduh Proposal</div>
          </button>
        </div>
      </div>
    </div>
  );
};
