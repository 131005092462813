import * as React from "react";

export const LineGraph = () => {
  return (
    <svg
      width={1879}
      height={640}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden xl:block absolute xl:-left-[750px] min-[1380px]:-left-[700px] min-[1450px]:-left-[550px] 2xl:-left-[510px] top-[20px]"
    >
      <path
        d="M1868 7C1522 275 503.167 420.667.5 447v193H1868V7z"
        fill="url(#prefix__paint0_radial_418_1266)"
        fillOpacity={0.6}
      />
      <path
        d="M1878.5.5l-34.35 4.518 21.09 27.485L1878.5.5zM.657 450.496c246.428-12.91 620.353-54.429 977.895-125.808 178.778-35.69 353.498-78.855 506.178-129.66 152.63-50.791 283.41-109.28 374.15-175.695l-3.54-4.842c-90 65.873-220.1 124.127-372.51 174.844-152.36 50.702-326.83 93.81-505.453 129.469-357.246 71.32-730.883 112.805-977.034 125.7l.314 5.992z"
        fill="#A1E5E6"
      />
      <defs>
        <radialGradient
          id="prefix__paint0_radial_418_1266"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(76.849 323.015 724.989) scale(219.763 1344.78)"
        >
          <stop stopColor="#A1E5E6" />
          <stop offset={1} stopColor="#A1E5E6" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
};
